import React, { FC, useEffect, useMemo, useState } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { captureException } from '@sentry/browser'
import { sortBy } from 'ramda'
import { GetAllClients } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Switch from 'src/components/Library/Switch/Switch'
import { useAuth } from 'src/Providers'

export type ClientType = Omit<GetAllClients['clients'][0], '__typename'>
const GET_ALL_CLIENTS = gql`
  query GetAllClients {
    clients {
      id
      name
    }
  }
`

interface Props {
  clientOnlyPrincipal: boolean
  contentGlobal: boolean
  setContentGlobal: (value: boolean) => void
  clientIds: number[]
  setClientIds: (ids: number[]) => void
  hasLoaded: () => void
  hasError: () => void
  setInputChangedACL?: (value: boolean) => void
}

const AccessControlList: FC<Props> = (props) => {
  const [clientsInputValue, setClientsInputValue] = useState('')

  const { currentUser } = useAuth()
  const isSuperAdmin = currentUser?.userData.role === 'SUPERADMIN'

  const onContentGlobal = (_: React.ChangeEvent, checked: boolean) => {
    props.setContentGlobal(checked)
    props.setInputChangedACL(true)
  }

  const {
    loading: queryLoading,
    error: queryError,
    data: clientsResponse,
  } = useQuery<GetAllClients>(GET_ALL_CLIENTS, {
    fetchPolicy: 'cache-first',
    onError(error) {
      captureException(error)
      props.hasError()
    },
  })

  const clients = useMemo(() => {
    if (queryLoading) {
      return []
    }

    return props.clientIds.map((id) => {
      const group = clientsResponse?.clients.find((client) => client.id === id)
      return group
    })
  }, [queryLoading, clientsResponse, props.clientIds])

  useEffect(() => {
    if (queryError) {
      props.hasError()
    }
    if (!queryLoading) {
      props.hasLoaded()
    }
  }, [queryError, queryLoading])

  return (
    <div data-testid="access-control-list-root">
      {!queryError && !queryLoading && (
        <div data-testid="access-control-list-root-loaded">
          <Card elevation={0} variant={'outlined'}>
            <CardHeader
              className={'bg-gray-50'}
              title={
                <div className="flex flex-row">
                  <div className="w-1/2 flex flex-row items-center text-gray-600">
                    <h3 className="font-normal text-base">
                      {!props.clientOnlyPrincipal && (
                        <span>Share with Clients</span>
                      )}
                      {props.clientOnlyPrincipal && (
                        <span>Restrict Clients</span>
                      )}
                    </h3>
                  </div>
                  <div className="flex flex-row items-center w-1/2 justify-end">
                    <Switch
                      disabled={!isSuperAdmin}
                      checked={props.contentGlobal}
                      onChange={onContentGlobal}
                    />
                  </div>
                </div>
              }
              subheader={
                <p className="text-xs text-gray-500 pt-3">
                  By making content shared with clients, only selected Clients
                  will be able to view this content.
                </p>
              }
            />
            <Collapse in={props.contentGlobal}>
              <CardContent>
                <Stack spacing={2}>
                  <div>
                    <p className="text-sm leading-5 font-normal text-gray-500">
                      Clients
                    </p>
                    <Autocomplete<ClientType, true, false, false>
                      className="w-full bg-white"
                      fullWidth
                      size="small"
                      value={clients}
                      disabled={!isSuperAdmin}
                      disableCloseOnSelect
                      filterSelectedOptions
                      multiple
                      onChange={(_event, newValue) => {
                        props.setClientIds(newValue.map((client) => client.id))
                        props.setInputChangedACL(true)
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      inputValue={clientsInputValue}
                      onInputChange={(_, newInputValue) => {
                        setClientsInputValue(newInputValue)
                      }}
                      id="dialogInputClientsAll"
                      options={sortBy((x) => x.name, clientsResponse.clients)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </Stack>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      )}
    </div>
  )
}

export default AccessControlList
