import { FC, useEffect, useState } from 'react'

import { captureException } from '@sentry/browser'
import { PROD_CLIENT } from 'api/src/common/enums'
import type {
  ACLPrincipalInput,
  ACLPrincipalType,
  DeleteACLForResource,
  DeleteACLForResourceVariables,
  GetACLForResource,
  GetACLForResourceVariables,
  SetACLForResource,
  SetACLForResourceVariables,
  TableName,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import Loading from 'src/components/Library/Loading/Loading'
import { useAuth } from 'src/Providers'

import AccessControlListForClients from './AccessControlListForClients'
import AccessControlListForMemberships from './AccessControlListForMemberships'

const GET_ACL_FOR_RESOURCE = gql`
  query GetACLForResource($input: ACLForResourceInput!) {
    aclForResource(input: $input) {
      id
      clientId
      principalType
      principalId
    }
  }
`

const SET_ACL_FOR_RESOURCE = gql`
  mutation SetACLForResource($input: ACLInput!) {
    aclForResourceSet(input: $input) {
      id
      clientId
      principalType
      principalId
    }
  }
`

const DELETE_ACL_FOR_RESOURCE = gql`
  mutation DeleteACLForResource($resourceId: Int!, $resourceType: TableName!) {
    aclForResourceDelete(resourceId: $resourceId, resourceType: $resourceType)
  }
`

export type AccessControlListType = FC<{
  membershipCardTitle?: string
  membershipCardText?: string
  groupsLabelText?: string
  membersLabelText?: string
  hideModalToggle?: boolean
}>

interface Props {
  resourceType: TableName

  /**
   * Only provide the resourceId if the Resource already exists.
   * If the Resource is being created, do not provide the resourceId,
   * the resourceId will be provided to the saveACL function.
   */
  resourceId?: number

  /**
   * The default value is false (Publicly accessible), but this can be override to true (Private).
   * Use this when creating a new Resource.
   */
  contentPrivateDefault?: boolean

  /**
   * The Principal types that are shown
   */
  principalTypes: ACLPrincipalType[]
}

export type SaveACLFn = (input?: {
  resourceId?: number
}) => Promise<SetACLForResource['aclForResourceSet']>
export type DeleteACLFn = (input?: { resourceId?: number }) => Promise<unknown>

const useACL = ({
  resourceType,
  resourceId,
  contentPrivateDefault = false,
  principalTypes,
}: Props) => {
  if (!principalTypes || principalTypes.length === 0) {
    throw new Error('principalTypes is required')
  }

  const [contentPrivate, setContentPrivate] = useState(contentPrivateDefault)
  const [contentGlobal, setContentGlobal] = useState(false)
  const [clientIds, setClientIds] = useState<number[]>([])
  const [membershipIds, setMembershipIds] = useState<number[]>([])
  const [membershipGroupIds, setMembershipGroupIds] = useState<number[]>([])
  const [isComponentLoading, setComponentIsLoading] = useState(false)
  const [hasComponentError, setHasComponentError] = useState(false)

  // Set variable, detect if input has changed
  const [inputChangedACL, setInputChangedACL] = useState<boolean>(false)

  const resetACLComponent = () => {
    setContentPrivate(contentPrivateDefault)
    setContentGlobal(false)
    setClientIds([])
    setMembershipIds([])
    setMembershipGroupIds([])
    setComponentIsLoading(true)
    setHasComponentError(false)
  }

  const { currentUser } = useAuth()
  const isSuperAdmin = currentUser.userData.role === 'SUPERADMIN'
  const isClientStafflink =
    currentUser.membershipData.clientId === PROD_CLIENT.STAFFLINK
  const clientOnlyPrincipal =
    principalTypes.length === 1 && principalTypes.includes('CLIENT')

  const { loading: queryLoading, error: queryError } = useQuery<
    GetACLForResource,
    GetACLForResourceVariables
  >(GET_ACL_FOR_RESOURCE, {
    fetchPolicy: 'network-only',
    skip: !resourceId,
    variables: { input: { resourceType, resourceId } },
    onCompleted: (data) => {
      const clientIds = !isClientStafflink
        ? []
        : data.aclForResource
            .filter((x) => x.principalType === 'CLIENT')
            .map((x) => x.principalId)
      setClientIds(clientIds)
      setContentGlobal(clientIds.length > 0)

      if (clientOnlyPrincipal) {
        const hasWildcardRule =
          data.aclForResource.length === 1 &&
          data.aclForResource[0].principalType === null
        if (!hasWildcardRule) {
          setContentGlobal(true)
        }

        // Break out early when Client only
        return
      }

      const membershipPrincipals = data.aclForResource.filter(
        (aclRule) =>
          aclRule.principalType === 'MEMBERSHIP' ||
          aclRule.principalType === 'MEMBERSHIPGROUP' ||
          aclRule.principalType === null,
      )

      const isContentPublic =
        membershipPrincipals.length === 1 &&
        membershipPrincipals[0].principalType === null &&
        membershipPrincipals[0].principalId === null
      setContentPrivate(!isContentPublic)

      const membershipIds = membershipPrincipals
        .filter((x) => x.principalType === 'MEMBERSHIP')
        .map((x) => x.principalId)
      setMembershipIds(membershipIds)

      const membershipGroupIds = membershipPrincipals
        .filter((x) => x.principalType === 'MEMBERSHIPGROUP')
        .map((x) => x.principalId)
      setMembershipGroupIds(membershipGroupIds)
    },
    onError: (error) => {
      captureException(error)
    },
  })

  const [setACLMutation] = useMutation<
    SetACLForResource,
    SetACLForResourceVariables
  >(SET_ACL_FOR_RESOURCE)

  const [deleteACLForResourceMutation] = useMutation<
    DeleteACLForResource,
    DeleteACLForResourceVariables
  >(DELETE_ACL_FOR_RESOURCE)

  const hasError = hasComponentError || !!queryError
  const isLoading = isComponentLoading || queryLoading

  const saveACL = async ({
    resourceId: newResourceId,
  }: { resourceId?: number } = {}) => {
    if (isLoading || hasError) {
      throw new Error('Cannot save ACL while loading or in error state')
    }

    const clientPrincipals: ACLPrincipalInput[] = clientIds.map((id) => ({
      principalType: 'CLIENT',
      principalId: id,
    }))

    let principals: ACLPrincipalInput[] = []

    if (clientOnlyPrincipal) {
      principals = contentGlobal
        ? // Slider is ON - zero or more Clients
          clientPrincipals
        : // Slider is OFF - Add a wildcard rule - Allow ALL Clients
          [null]
    } else {
      const memberPrincipals: ACLPrincipalInput[] = membershipIds.map((id) => ({
        principalType: 'MEMBERSHIP',
        principalId: id,
      }))

      const groupPrincipals: ACLPrincipalInput[] = membershipGroupIds.map(
        (id) => ({
          principalType: 'MEMBERSHIPGROUP',
          principalId: id,
        }),
      )

      principals = [
        ...(isClientStafflink && isSuperAdmin ? clientPrincipals : []),
        ...(contentPrivate
          ? [...memberPrincipals, ...groupPrincipals]
          : [null]),
      ]
    }

    const result = await setACLMutation({
      variables: {
        input: {
          resourceType,
          resourceId: newResourceId ?? resourceId,
          principals,
        },
      },
    })

    return result?.data?.aclForResourceSet
  }

  const deleteACL = async ({
    resourceId: newResourceId,
  }: { resourceId?: number } = {}) => {
    const result = await deleteACLForResourceMutation({
      variables: {
        resourceId: newResourceId ?? resourceId,
        resourceType,
      },
    })

    return result
  }

  useEffect(() => {
    /**
     * Input was change for ACL item
     * Reset to false and listen for next input change
     */
    if (inputChangedACL) {
      setInputChangedACL(false)
    }
  }, [inputChangedACL])

  const showClientsComponent =
    principalTypes.includes('CLIENT') && isClientStafflink
  const showMembershipsComponent =
    principalTypes.includes('MEMBERSHIP') ||
    principalTypes.includes('MEMBERSHIPGROUP')

  const Component: AccessControlListType = (props) => (
    <div className="access-control-list-component">
      <>
        {hasError && <div>Error occurred...</div>}

        {isLoading && (
          <div className="max-h-[300px]">
            <Loading />
          </div>
        )}

        <div hidden={isLoading || hasError}>
          {showMembershipsComponent && (
            <AccessControlListForMemberships
              {...{
                membershipCardTitle: props.membershipCardTitle,
                membershipCardText: props.membershipCardText,
                groupsLabelText: props.groupsLabelText,
                membersLabelText: props.membersLabelText,
                hideModalToggle: props.hideModalToggle,
                contentPrivate,
                setContentPrivate,
                membershipIds,
                membershipGroupIds,
                setMembershipIds,
                setMembershipGroupIds,
                hasError: () =>
                  !hasComponentError && setHasComponentError(true),
                hasLoaded: () =>
                  isComponentLoading && setComponentIsLoading(false),
                setInputChangedACL,
              }}
            />
          )}
          {showMembershipsComponent && showClientsComponent && (
            <div className="mb-2" />
          )}
          {showClientsComponent && (
            <AccessControlListForClients
              {...{
                clientOnlyPrincipal,
                contentGlobal,
                setContentGlobal,
                clientIds,
                setClientIds,
                hasError: () =>
                  !hasComponentError && setHasComponentError(true),
                hasLoaded: () =>
                  isComponentLoading && setComponentIsLoading(false),
                setInputChangedACL,
              }}
            />
          )}
        </div>
      </>
    </div>
  )

  return {
    resetACLComponent,
    AccessControlList: Component,
    saveACL,
    deleteACL,
    inputChangedACL,
    isLoading,
  }
}

export default useACL
