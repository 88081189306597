import React, { FC, useEffect, useMemo, useState } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { captureException } from '@sentry/browser'
import { GetAllGroups, GetAllMemberships } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Switch from 'src/components/Library/Switch/Switch'

export type MembershipGroupType = Omit<
  GetAllGroups['membershipGroups'][0],
  '__typename'
>
const GET_ALL_GROUPS = gql`
  query GetAllGroups {
    membershipGroups: membershipGroupsByClient {
      id
      name
    }
  }
`

export type MembershipType = Omit<
  GetAllMemberships['memberships'][0],
  '__typename'
>
const GET_ALL_MEMBERSHIPS = gql`
  query GetAllMemberships {
    memberships: membershipsByClient(activeOnly: true) {
      id
      user {
        id
        name
      }
    }
  }
`

interface Props {
  membershipCardTitle?: string
  membershipCardText?: string
  groupsLabelText?: string
  membersLabelText?: string
  hideModalToggle?: boolean
  contentPrivate: boolean
  setContentPrivate: (value: boolean) => void
  membershipIds: number[]
  membershipGroupIds: number[]
  setMembershipIds: (ids: number[]) => void
  setMembershipGroupIds: (ids: number[]) => void
  hasLoaded: () => void
  hasError: () => void
  setInputChangedACL?: (value: boolean) => void
}

const AccessControlListForMemberships: FC<Props> = (props) => {
  const [groupsInputValue, setGroupsInputValue] = useState('')
  const [memberInputValue, setMemberInputValue] = useState('')
  const {
    loading: groupsLoading,
    error: groupsError,
    data: allMembershipGroups,
  } = useQuery<GetAllGroups>(GET_ALL_GROUPS, {
    fetchPolicy: 'cache-first',
    onError(error) {
      captureException(error)
      props.hasError()
    },
  })

  const membershipGroups = useMemo(() => {
    if (groupsLoading) {
      return []
    }

    return props.membershipGroupIds
      .map((id) => {
        const group = allMembershipGroups?.membershipGroups.find(
          (group) => group.id === id,
        )
        return group
      })
      .filter(Boolean)
  }, [groupsLoading, allMembershipGroups, props.membershipGroupIds])

  const {
    loading: membershipsLoading,
    error: membershipsError,
    data: allMemberships,
  } = useQuery<GetAllMemberships>(GET_ALL_MEMBERSHIPS, {
    fetchPolicy: 'cache-first',
    onError(error) {
      captureException(error)
      props.hasError()
    },
  })

  const memberships = useMemo(() => {
    if (membershipsLoading) {
      return []
    }

    return props.membershipIds
      .map((id) => {
        const membership = allMemberships?.memberships.find(
          (membership) => membership.id === id,
        )
        return membership
      })
      .filter(Boolean)
  }, [membershipsLoading, allMemberships, props.membershipIds])

  const error = groupsError || membershipsError
  const loading = groupsLoading || membershipsLoading

  useEffect(() => {
    if (error) {
      props.hasError()
    }
    if (!loading) {
      props.hasLoaded()
    }
  }, [error, loading])

  return (
    <div data-testid="access-control-list-root">
      {!error && !loading && (
        <div data-testid="access-control-list-root-loaded">
          <Card elevation={0} variant={'outlined'}>
            <CardHeader
              className={'bg-gray-50'}
              title={
                <div className="flex flex-row">
                  <div className="w-1/2 flex flex-row items-center text-gray-600">
                    <h3 className="font-normal text-base">
                      {props.membershipCardTitle ?? 'Restrict content'}
                    </h3>
                  </div>
                  <div className="flex flex-row items-center w-1/2 justify-end">
                    {!props.hideModalToggle && (
                      <Switch
                        data-testid="access-control-list-restrict-content-switch"
                        checked={props.contentPrivate}
                        onChange={(_: React.ChangeEvent, checked: boolean) => {
                          props.setInputChangedACL(true)
                          props.setContentPrivate(checked)
                        }}
                      />
                    )}
                  </div>
                </div>
              }
              subheader={
                props.membershipCardText ? (
                  <p className="text-xs text-gray-500 pt-3">
                    By making content restricted, only selected members and
                    groups will be able to view this content.
                  </p>
                ) : null
              }
            />
            <Collapse in={props.contentPrivate}>
              <CardContent>
                <Stack spacing={2}>
                  <div>
                    <p className="text-sm leading-5 font-normal text-gray-500">
                      {props.groupsLabelText ?? 'Groups'}
                    </p>
                    <Autocomplete<MembershipGroupType, true, false, false>
                      className="w-full bg-white"
                      fullWidth
                      size="small"
                      value={membershipGroups}
                      disableCloseOnSelect
                      filterSelectedOptions
                      multiple
                      onChange={(_event, newValue) => {
                        props.setMembershipGroupIds(
                          newValue.map((group) => group.id),
                        )
                        props.setInputChangedACL(true)
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      inputValue={groupsInputValue}
                      onInputChange={(_, newInputValue) => {
                        setGroupsInputValue(newInputValue)
                      }}
                      id="dialogInputGroupsAll"
                      data-testid="ACL-groups-input"
                      options={allMembershipGroups.membershipGroups}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                  <div>
                    <p className="text-sm leading-5 font-normal text-gray-500">
                      {props.membersLabelText ?? 'Members'}
                    </p>
                    <Autocomplete<MembershipType, true, false, false>
                      className="w-full bg-white"
                      fullWidth
                      size="small"
                      value={memberships}
                      disableCloseOnSelect
                      filterSelectedOptions
                      multiple
                      onChange={(_event, newValue) => {
                        props.setMembershipIds(
                          newValue.map((member) => member.id),
                        )
                        props.setInputChangedACL(true)
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      inputValue={memberInputValue}
                      onInputChange={(_, newInputValue) => {
                        setMemberInputValue(newInputValue)
                      }}
                      id="dialogInputMembersAll"
                      data-testid="ACL-memberships-input"
                      options={allMemberships.memberships}
                      getOptionLabel={(option) => option.user.name}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </Stack>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      )}
    </div>
  )
}

export default AccessControlListForMemberships
